:root {
  --blue: #0e72e6;
  --gray: #e6e6e6;
  --green: #5CB8B2;
  --near-white: #f9f9f9;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

body, html {
  height: 100%;
  overflow-x: hidden;
}

a {
  cursor: pointer;
}

button {
  cursor: pointer;
  text-decoration: none;
  outline: none;
  border: none;
}

button:focus {
  border: none;
  outline:none;
}

.home-bordered-text {
  background-color: #526978;
  color: #ffffff;
  pointer-events: none;
  border-radius: 20px;
  border: 2px #ffffff;
}

.bg-white {
  background-color: #ffffff;
}

@media only screen and (max-width: 425px) {
  .home-bordered-text-dynamic {
    font-size: smaller;
  }
}

@media only screen and (max-width: 425px) {
  .btn-small {
    margin: 0;
  }
}

/* Custom Input Boxes */
.tabs input[name="tab-control"] {
  display: none;
}

.uk-grass {
  background-color: rgb(225, 225, 225);
}

.grass-selection {
  cursor: pointer;
}

.selected-grass, .grass-selection:hover {
  border: 2px solid #0e72e6;
  box-shadow: 0 0 0 2px #c8dffa;
}

.active-activity {
  background-color: #00b3a4;
}

/* IE Scroll Bar Styling */
html, body {
  scrollbar-face-color:#5CB8B2;
  scrollbar-arrow-color:#F5F5F5;
  scrollbar-track-color:#F5F5F5;
  scrollbar-3dlight-color:#F5F5F5;
}

.img-link:hover {
  cursor: pointer;
}

[name="radio-group"]:checked, [name="radio-group"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[name="radio-group"]:checked + label, [name="radio-group"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

[name="radio-group"]:checked + label:before, [name="radio-group"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

[name="radio-group"]:checked + label:after, [name="radio-group"]:not(:checked) + label:after {
  content: '';
  width: 20px;
  height: 20px;
  background: rgb(255, 59, 59);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[name="radio-group"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[name="radio-group"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.spring-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: all .1s ease-in-out;
  background-image: -webkit-image-set(
          url('https://cdn.lawncareplanners.com/seasons/background_spring.webp') 1x,
          url('https://cdn.lawncareplanners.com/seasons/background_spring.jp2') 1x,
          url('https://cdn.lawncareplanners.com/seasons/background_spring.jpg') 1x);
  background-image: image-set(
          url('https://cdn.lawncareplanners.com/seasons/background_spring.webp') 1x,
          url('https://cdn.lawncareplanners.com/seasons/background_spring.jp2') 1x,
          url('https://cdn.lawncareplanners.com/seasons/background_spring.jpg') 1x);
}

.summer-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: all .1s ease-in-out;
  background-image: -webkit-image-set(
          url('https://cdn.lawncareplanners.com/seasons/background_summer.webp') 1x,
          url('https://cdn.lawncareplanners.com/seasons/background_summer.jp2') 1x,
          url('https://cdn.lawncareplanners.com/seasons/background_summer.jpg') 1x);
  background-image: image-set(
          url('https://cdn.lawncareplanners.com/seasons/background_summer.webp') 1x,
          url('https://cdn.lawncareplanners.com/seasons/background_summer.jp2') 1x,
          url('https://cdn.lawncareplanners.com/seasons/background_summer.jpg') 1x);
}

.fall-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: all .1s ease-in-out;
  background-image: -webkit-image-set(
          url('https://cdn.lawncareplanners.com/seasons/background_fall.webp') 1x,
          url('https://cdn.lawncareplanners.com/seasons/background_fall.jp2') 1x,
          url('https://cdn.lawncareplanners.com/seasons/background_fall.jpg') 1x);
  background-image: image-set(
          url('https://cdn.lawncareplanners.com/seasons/background_fall.webp') 1x,
          url('https://cdn.lawncareplanners.com/seasons/background_fall.jp2') 1x,
          url('https://cdn.lawncareplanners.com/seasons/background_fall.jpg') 1x);
}

.winter-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: all .1s ease-in-out;
  background-image: -webkit-image-set(
          url('https://cdn.lawncareplanners.com/seasons/background_winter.webp') 1x,
          url('https://cdn.lawncareplanners.com/seasons/background_winter.jp2') 1x,
          url('https://cdn.lawncareplanners.com/seasons/background_winter.jpg') 1x);
  background-image: image-set(
          url('https://cdn.lawncareplanners.com/seasons/background_winter.webp') 1x,
          url('https://cdn.lawncareplanners.com/seasons/background_winter.jp2') 1x,
          url('https://cdn.lawncareplanners.com/seasons/background_winter.jpg') 1x);
}

.illustration {
  @media screen and (min-width: 30em) {
    width: 90px;
  }

  @media screen and (max-width: 30em) {
    width: 45px;
  }
}

* {
  font-family: 'Open Sans', sans-serif;
}

.home-ui {
  background-color: rgba(184, 199, 202, 0.75);
  border-color: #009688;
  border-width: 3px;
  margin: 20px 0;
  padding: 10px !important;
  border-radius: 20px;
}

.home-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: all .1s ease-in-out;

  background-image: -webkit-image-set(
          url("https://cdn.lawncareplanners.com/background_home_test.webp") 1x,
          url("https://cdn.lawncareplanners.com/background_home_test.jp2") 1x,
          url("https://cdn.lawncareplanners.com/background_home_test.jpg") 1x);
  background-image: image-set(
          url("https://cdn.lawncareplanners.com/background_home_test.webp") 1x,
          url("https://cdn.lawncareplanners.com/background_home_test.jp2") 1x,
          url("https://cdn.lawncareplanners.com/background_home_test.jpg") 1x);
}

.home-container {
  @media only screen and (max-width: 1279px) {
    margin: 0 auto;
  }

  @media only screen and (min-width: 1280px) {
    max-width: 1280px;
    padding-top: 20px;
    align-items: center;
  }
}

#root {
  padding-bottom: 0 !important;
  min-height: 100vh;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Quicksand', sans-serif;
}

h2 {
  font-size: 2em;
}

h3 {
  margin: 1rem 0;
  font-size: 1.17em;
  font-weight: 500;
}

img {
  max-width: 100%;
}

.loading-icon {
  margin: 0 auto;
}

.grass-selection {
  padding: 0;
  border-radius: .25rem;
  border: 1px solid rgba(0,0,0,.1);
}

.grass-selection img {
  width: 100%;
}

.product img {
  max-width: 100%;
  width: 120px;
}

.product-description h3 {
  margin-top: 0;
}

.tabs .tab a:hover, .tabs .tab a.active {
  font-weight: bold;
}

.article-container {
  background-color: white;
}

.article-container h2 {
  margin-top: 0;
  padding-top: 1em;
}

@media (max-width: 767px){
  .grass-selection {
    margin: 1rem 4rem;
  }

  .product img {
    width: 77px;
  }

  .toggle-filter {
    display: block;
  }
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

blockquote {
  border-left: 5px solid #005D53;
}

.bg-teal-lighter {
  background-color: #42B2A6;
}

.bg-dark {
  background-color: rgb(51,51,51);
}

.bg-light {
  background-color: rgba(51,51,51,.8);
}

.bg-white {
  background-color: white;
}

.grey-dark-text{
  color: rgb(51,51,51);
}

.btn.btn-dark {
  background-color: rgb(51,51,51);
}

.season a {
  color: white !important;
}

.active-season {
  text-decoration: underline;
  background-color: #00b3a4;
};

